import { MdPlayArrow } from "react-icons/md";
import { BoldBlock, ContentBlock, IconTitle, PrivacyBlock, TermsBlock } from "../../styles/policy/PolicyElements";

const PolicyForm = ({ type }) => {
    return (
        <ContentBlock>
            {type === "terms" && (
                <TermsBlock>
                    <div>
                        <h3>제1조 (목적)</h3>
                        <p>
                            본 약관은 유퀴즈(이하 “회사”)가 운영하는 웹사이트(이하 “사이트”)에서 제공하는 인터넷 관련 서비스를 이용함에 있어 사이트와 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 한다.
                        </p>
                    </div>
                    <div>
                        <h3>제2조 (정의)</h3>
                        <p>이 약관에서 사용하는 용어의 정의는 아래와 같다.</p>
                        <ul>
                            <li>1. "사이트"라 함은 "회사"가 서비스를 "회원"에게 제공하기 위하여 컴퓨터 등 정보 통신 설비를 이용하여 설정한 가상의 영업장 또는 "회사"가 운영하는 아래 웹사이트를 말한다.</li>
                            <li>2. "서비스"라 함은 "회사"가 사이트를 통해 개인이 등록한 자료를 DB화하여 각각의 목적에 맞게 분류, 가공, 집계하여 정보를 제공하는 서비스, 개인이 구직 등의 목적으로 등록한 자료를 제공받는 서비스, 기업에 관한 자료를 수집, 분류, 가공하여 정보를 제공하는 서비스 등 및 이들 서비스와 관련하여 각 “사이트”에서 제공하는 모든 부대/제휴 서비스를 총칭한다.</li>
                            <li>3. "회원"이라 함은 서비스를 이용하기 위하여 동 약관에 동의하거나 페이스북 등 연동 된 서비스를 통해 "회사"와 이용 계약을 체결한 개인을 말한다.</li>
                            <li>4. "아이디"라 함은 회원의 식별과 회원의 서비스 이용을 위하여 "회원"이 가입 시 사용한 이메일 주소를 말한다.</li>
                            <li>5. "비밀번호"라 함은 "회사"의 서비스를 이용하려는 사람이 아이디를 부여받은 자와 동일인임을 확인하고 "회원"의 권익을 보호하기 위하여 "회원"이 선정한 문자와 숫자의 조합을 말한다.</li>
                            <li>6. "비회원"이라 함은 "회원"에 가입하지 않고 "회사"가 제공하는 서비스를 이용하려는 자를 말한다.</li>
                            <li>7. “콘텐츠”라 함은 "회원"이 등록한 개인정보와 사이트에 게시한 퀴즈 정보 등을 말한다.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>제3조 (약관의 명시와 개정)</h3>
                        <ul>
                            <li>1. "회사"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 "회원"이 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 "회원"에게 공지해야 한다.</li>
                            <li>2. "회사"는 약관의규제등에관한법률, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법률, 개인정보보호법 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있다.</li>
                            <li>3. "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지한다. 단 "회원"의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록 한다.</li>
                            <li>4. "회원"은 변경된 약관에 대해 거부할 권리가 있다. "회원"은 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있다. "회원"이 거부하는 경우 본 서비스 제공자인 "회사"는 15일의 기간을 정하여 "회원"에게 사전 통지 후 당해 "회원"과의 계약을 해지할 수 있다. 만약, "회원"이 거부의사를 표시하지 않거나, 전항에 따라 시행일 이후에 서비스를 이용하는 경우에는 동의한 것으로 간주한다.</li>
                            <li>5. "회사"는 "회원"이 가입 신청 시 등록한 이메일, 휴대전화 문자메시지 중 "회원"이 수신에 동의한 수단을 활용하여, "회원"에 대한 각종 고지나 통지를 이행할 수 있다. 단, "회원"에게 서비스 이용에 대한 정보를 고지, 통지 및 공지가 필요할 경우 수신에 동의한 이외의 수단으로 이행할 수 있다.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>제4조 (저작권의 귀속 및 이용제한)</h3>
                        <ul>
                            <li>1. "회사"가 작성한 저작물에 대한 저작권 기타 지적재산권은 "회사"에 귀속한다.</li>
                            <li>2. 이용자는 "회사"를 이용함으로써 얻은 정보 중 "회사"에게 지적재산권이 귀속된 정보를 "회사"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안된다.</li>
                            <li>3. "회사"는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 한다.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>제5조 (서비스의 중단)</h3>
                        <ul>
                            <li>1. "회사"는 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있다.</li>
                            <li>2. "회사"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, "회사"가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</li>
                            <li>3. 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "회사"은 제8조에 정한 방법으로 이용자에게 통지하고 당초 "회사"에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, "회사"가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 "회사"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급한다.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>제6조 (회원의 개인정보보호)</h3>
                        <p>"회사"는 "회원"의 개인정보보호를 위하여 노력해야 한다. "회원"의 개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령과 “회사”가 "사이트"에 게시하는 개인정보취급방침에 따른다.</p>
                    </div>
                    <div>
                        <h3>제7조 (분쟁해결)</h3>
                        <ul>
                            <li>1. "회사"와 "회원"은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위해 필요한 모든 노력을 하여야 한다.</li>
                            <li>2."회사"와 과 "회원" 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.</li>
                            <li>3. "회사"와 과 "회원" 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>부칙</h3>
                        <p>- 이 약관은 2023년 9월 1일부터 시행한다.</p>
                    </div>
                </TermsBlock>
            )}
            {type === "privacy" && (
                <PrivacyBlock>
                    <p>
                        유퀴즈 ('www.wequiz.co.kr'이하 '유퀴즈')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                    </p>
                    <ol>
                        <li>개인정보의 처리 목적</li>
                        <li>개인정보의 처리 및 보유 기간</li>
                        <li>처리하는 개인정보의 항목</li>
                        <li>개인정보의 제3자 제공에 관한 사항</li>
                        <li>개인정보의 파기절차 및 파기방법</li>
                        <li>정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항</li>
                        <li>개인정보의 안전성 확보조치에 관한 사항</li>
                        <li>개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항</li>
                        <li>개인정보 보호책임자에 관한 사항</li>
                        <li>인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항</li>
                    </ol>
                    <div>
                        <h3>제1조(개인정보의 처리 목적)</h3>
                        <BoldBlock>
                            유퀴즈 ('www.wequiz.co.kr'이하 '유퀴즈')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                        </BoldBlock>
                        <p>
                            1. 홈페이지 회원가입 및 관리<br/>
                            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지 목적으로 개인정보를 처리합니다.
                        </p>
                        <p>
                            2. 재화 또는 서비스 제공<br/>
                            서비스 제공, 콘텐츠 제공을 목적으로 개인정보를 처리합니다.
                        </p>
                        <p>
                            3. 마케팅 및 광고에의 활용<br/>
                            접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                        </p>
                    </div>
                    <div>
                        <h3>제2조(개인정보의 처리 및 보유 기간)</h3>
                        <p>1. 유퀴즈 은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</p>
                        <p>2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
                        <ul>
                            <li>[홈페이지 회원가입 및 관리]</li>
                            <li>홈페이지 회원가입 및 관리와 관련한 개인정보는 수집.이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유.이용됩니다.</li>
                            <li>보유근거 : 서비스 이용 외 다른 이용 없음</li>
                        </ul>
                    </div>
                    <div>
                        <h3>제3조(처리하는 개인정보의 항목)</h3>
                        <p>1. 유퀴즈 은(는) 다음의 개인정보 항목을 처리하고 있습니다.</p>
                        <ul>
                            <li>[홈페이지 회원가입 및 관리]</li>
                            <li>필수항목 : 이름, 로그인ID, 이메일, 서비스 이용 기록</li>
                        </ul>
                    </div>
                    <div>
                        <h3>제4조(개인정보의 제3자 제공에 관한 사항)</h3>
                        <p>1. 유퀴즈 은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
                        <p>2. 유퀴즈 은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</p>
                        <ul>
                            <li>개인정보를 제공받는 자: 홈페이지 관리자</li>
                            <li>제공받는 자의 개인정보 이용목적 : 서비스 제공과 연관된 목적</li>
                            <li>제공받는 자의 보유.이용기간 : 1년</li>
                        </ul>
                    </div>
                    <div>
                        <h3>제5조(개인정보의 파기절차 및 파기방법)</h3>
                        <div>
                            <p>1. 유퀴즈 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
                            <p>2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p>
                            <p>3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
                            <p>
                                - 파기절차<br />
                                유퀴즈 은(는) 파기 사유가 발생한 개인정보를 선정하고, 유퀴즈 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                            </p>
                            <p>
                                - 파기방법<br />
                                전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h3>제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</h3>
                        <div>
                            <p>1. 정보주체는 유퀴즈에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
                            <p>2. 제1항에 따른 권리 행사는유퀴즈에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 유퀴즈은(는) 이에 대해 지체 없이 조치하겠습니다.</p>
                            <p>3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
                            <p>4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</p>
                            <p>5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
                            <p>6. 유퀴즈은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p>
                        </div>
                    </div>
                    <div>
                        <h3>제7조(개인정보의 안전성 확보조치에 관한 사항)</h3>
                        <BoldBlock>유퀴즈 은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</BoldBlock>
                        <p>
                            1. 개인정보에 대한 접근 제한<br />
                            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
                        </p>
                        <p>
                            2. 개인정보의 암호화
                            이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                        </p>
                    </div>
                    <div>
                        <h3>제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</h3>
                        <div>
                            <p>유퀴즈 은(는) 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.</p>
                        </div>
                    </div>
                    <div>
                        <h3>제9조 (개인정보 보호책임자에 관한 사항)</h3>
                        <div>
                            <p>1. 유퀴즈 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
                            <IconTitle><MdPlayArrow /> 개인정보 보호책임자</IconTitle>
                            <ul>
                                <li>성 명 : 김소진</li>
                                <li>직 책 : 관리자</li>
                                <li>직 급 : 관리자</li>
                                <li>연락처 : 010-8900-1145</li>
                                <li>이메일 : sisoj1253@gmail.com</li>
                            </ul>
                            <p>2. 정보주체께서는 유퀴즈 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 유퀴즈 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>
                        </div>
                    </div>
                    <div>
                        <h3>제10조(개인정보의 열람청구를 접수·처리하는 부서)</h3>
                        <BoldBlock>정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 유퀴즈 은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</BoldBlock>
                        <IconTitle><MdPlayArrow /> 개인정보 열람청구 접수·처리 부서</IconTitle>
                        <ul>
                            <li>부서명 : 유퀴즈부서</li>
                            <li>담당자 : 김소진</li>
                            <li>연락처 : 010-8900-1145</li>
                        </ul>
                    </div>
                    <div>
                        <h3>제11조(정보주체의 권익침해에 대한 구제방법)</h3>
                        <p>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</p>
                        <p>1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
                        <p>2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
                        <p>3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
                        <p>4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</p>
                        <p>「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</p>
                        <p>* 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</p>
                    </div>
                    <div>
                        <h3>제12조(개인정보 처리방침 변경)</h3>
                        <div>
                            <p>1. 이 개인정보처리방침은 2023년 9월 1부터 적용됩니다.</p>
                            <p>
                                2. 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
                                <br /><br />
                                - 현재 버전이 가장 최신
                            </p>
                        </div>
                    </div>
                </PrivacyBlock>
            )}
        </ContentBlock>
    );
};

export default PolicyForm;